const Header = () => {
  return (
    <header id="header">
      <img src="images/usefulcoin-whiteicon-128x128px.png" width="75px" height="75px" alt="white icon" />
      <h2>USEFUL COIN</h2>
    </header>
  );
}

export default Header;
